<script setup lang="ts">
import { VNavigationDrawer } from "vuetify/components/VNavigationDrawer";
// icons imports
import {
  mdiHome,
  mdiAccountMultiple,
  mdiDomain,
  mdiReceipt,
  mdiCash,
  mdiAccountMultipleOutline,
  mdiTicketPercent,
  mdiAutorenew,
} from "@mdi/js";
import type { AdminRole } from "@mono/constants/lib/adminAccessRules";
import type { RoutesNamedLocations } from "@typed-router";

const { checkPermission } = useAuthorization();

// v-model
const modelValue = defineModel<boolean>({
  default: false,
});

// can be used to add links to the sidebar
// either as a single link or as a group of links
type Link =
  | {
      title: string;
      to: RoutesNamedLocations;
      icon?: string;
      subLinks?: never;
      exact?: boolean;
      adminRolesAllowed?: AdminRole[];
    }
  | {
      title: string;
      to?: never;
      icon?: string;
      adminRolesAllowed?: AdminRole[];
      subLinks: {
        title: string;
        to: RoutesNamedLocations;
        icon?: string;
        exact?: boolean;
        adminRolesAllowed?: AdminRole[];
      }[];
    };

const drawerLinks = computed<Link[]>(() => [
  {
    title: "Home",
    to: { name: "dashboard" },
    icon: mdiHome,
    exact: true,
    adminRolesAllowed: ["support"],
  },
  {
    title: "Manage Users",
    to: { name: "dashboard-users" },
    icon: mdiAccountMultiple,
  },
  {
    title: "Manage Organizations",
    to: { name: "dashboard-organizations" },
    icon: mdiDomain,
  },
  {
    title: "Manage Orders",
    to: { name: "dashboard-orders" },
    icon: mdiReceipt,
    adminRolesAllowed: ["support"],
  },
  {
    title: "Manage Subscriptions Renewals",
    to: { name: "dashboard-subscription-renewals" },
    icon: mdiAutorenew,
    adminRolesAllowed: ["support"],
  },
  {
    title: "Financial Analytics",
    to: { name: "dashboard-financial" },
    icon: mdiCash,
  },
  {
    title: "Manage Affiliates",
    to: { name: "dashboard-affiliates" },
    icon: mdiAccountMultipleOutline,
  },
  {
    title: "Manage Promotions",
    to: { name: "dashboard-promotions" },
    icon: mdiTicketPercent,
  },
  {
    title: "Manage Plans",
    to: { name: "dashboard-plans" },
    icon: mdiCash,
  },
  {
    title: "Manage Admins",
    to: { name: "dashboard-admins" },
    icon: mdiAccountMultipleOutline,
  },
]);
</script>

<template>
  <VNavigationDrawer v-model="modelValue">
    <MList v-if="!!drawerLinks">
      <template
        v-for="(link, index) in drawerLinks"
        :key="index"
      >
        <template
          v-if="checkPermission({ adminRolesAllowed: link.adminRolesAllowed })"
        >
          <ListItemLink
            v-if="!link.subLinks"
            :key="`${link.title}-${index}`"
            :route="link.to"
            :prependIcon="link.icon"
            :title="link.title"
            :exact="!!link.exact"
          />

          <MListGroup v-else>
            <template #activator="{ props: slotProps }">
              <MListItem v-bind="slotProps">
                <template #title>
                  {{ link.title }}
                </template>
              </MListItem>
            </template>
            <template
              v-for="(subLink, subIndex) in link.subLinks"
              :key="`${link.title}-${subLink.title}-${subIndex}`"
            >
              <ListItemLink
                v-if="
                  checkPermission({
                    adminRolesAllowed: subLink.adminRolesAllowed,
                  })
                "
                :route="subLink.to"
                :prependIcon="subLink.icon"
                :title="subLink.title"
                :exact="!!subLink.exact"
              />
            </template>
          </MListGroup>
        </template>
      </template>
    </MList>
  </VNavigationDrawer>
</template>

<style>
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none !important;
}

.v-navigation-drawer__content {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>
